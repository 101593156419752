import React from 'react';

import { Posts } from '../components/Posts';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Blog" />
    <Posts />
  </Layout>
);

export default IndexPage;
